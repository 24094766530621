import { GraphQLClient } from 'graphql-request'
import { EventTarget } from 'event-target-shim'

const client = new GraphQLClient(`${process.env.GATSBY_API_URL}/graphql`)
let appInfo
let holidays
let isAppInfoInFlight = false
let isHolidaysInFlight = false

class AppEventEmitter extends EventTarget {
  getHolidays (startDate, daysOut) {
    if (isHolidaysInFlight) return // Just a safeguard to make sure we don't do this query twice

    if (holidays) {
      return Promise.resolve(this.dispatchEvent(new window.CustomEvent('app-holidays', { detail: holidays })))
    }

    isHolidaysInFlight = true
    client.request(`
      {
        holidays{
          start
        }
      }
    `)
      .then((result) => {
        isHolidaysInFlight = false
        holidays = result
        return this.dispatchEvent(new window.CustomEvent('app-holidays', { detail: holidays }))
      })
      .catch((error) => console.error(error))
  }

  getAppInfo () {
    if (isAppInfoInFlight) return // Just a safeguard to make sure we don't do this query twice

    if (appInfo) {
      return Promise.resolve(this.dispatchEvent(new window.CustomEvent('app-info', { detail: appInfo })))
    }

    isAppInfoInFlight = true
    return client.request(`
    {
      hoursOfOperation {
        isOpen
      }
      websiteNotification {
        type
        copyText
        displayOnLandingPages
      }
    }
    `)
      .then((result) => {
        isAppInfoInFlight = false
        appInfo = result
        this.dispatchEvent(new window.CustomEvent('app-info', { detail: appInfo }))
      })
      .catch((error) => console.error(error))
  }

  runFaff () {
    return this.dispatchEvent(new window.CustomEvent('run-faff'))
  }
}

export const appEventEmitter = new AppEventEmitter()
