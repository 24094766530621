export function quickLinks (document, appEventEmitter) {
  function calculateScrollDistance (offsetTop) {
    offsetTop = parseInt(offsetTop, 10)

    let headerHeight = 0
    let navHeight = 0
    const navigations = document.querySelectorAll('nav')
    const header = document.querySelector('header')

    if (header) {
      headerHeight = header.clientHeight
    }

    if (navigations) {
      navigations.forEach(nav => {
        navHeight += nav.firstElementChild.clientHeight
      })
    }

    return offsetTop - (navHeight + headerHeight)
  }

  function scrollToElement (id) {
    const element = document.querySelector(id)

    if (element) {
      // We need to push scrolling to the end of callstack
      setTimeout(window.scrollTo.bind(null, 0, calculateScrollDistance(element.offsetTop), 0))
    }
  }

  function onHashChange () {
    const { hash } = window.location

    if (hash) {
      scrollToElement(hash)
    }
  }

  window.addEventListener('load', onHashChange, false)
  window.addEventListener('popstate', onHashChange, false)
}
