// notice is global to all pages and is not a "module"
import { getFromMap, insertAfter, removeSelf } from './utilities'

const typeToBackgroundColor = {
  NOTICE: 'bg-lightest-green',
  WARNING: 'bg-red'
}

export function notice (document, appEventEmitter) {
  appEventEmitter.addEventListener('app-info', handleAppInfo)

  function addNotice (copyText, type = 'NOTICE', displayOnLandingPages) {
    const mainTag = document.querySelector('main')
    const isLandingPage = mainTag && mainTag.dataset.jsPageType === 'LANDING_PAGE'

    if (isLandingPage && !displayOnLandingPages) {
      return
    }

    const insertAfterEl = document.getElementById('article-content-area')
    if (!insertAfterEl) {
      throw new Error('notice: Failed to set notice, no element found to put notice after.')
    }

    const noticeEl = document.createElement('div')
    const typeClass = getFromMap(typeToBackgroundColor, type, 'bg-green')
    noticeEl.className = `${typeClass} notice flex items-start md:items-center p-xs text-black text-center leading-normal`

    addMessage(noticeEl, copyText)
    addDismissButton(noticeEl)
    insertAfter(noticeEl, insertAfterEl)
  }

  function addMessage (noticeEl, noticeText) {
    const messageEl = document.createElement('div')
    messageEl.className = 'w-full'
    messageEl.innerHTML = noticeText

    noticeEl.appendChild(messageEl)
  }

  function handleAppInfo ({ detail }) {
    const { websiteNotification } = detail
    if (!websiteNotification.copyText || document.cookie.indexOf('scms_notice') !== -1 || document.cookie.indexOf('bdso_notice') !== -1) {
      return
    }

    addNotice(websiteNotification.copyText, websiteNotification.type, websiteNotification.displayOnLandingPages)
  }

  function addDismissButton (noticeEl) {
    const buttonEl = document.createElement('button')
    buttonEl.className = 'p-sm md:pr-0 text-black cursor-pointer'
    buttonEl.addEventListener('click', () => dismissNotice(noticeEl))
    buttonEl.setAttribute('aria-label', 'Close')
    buttonEl.textContent = 'X'

    noticeEl.appendChild(buttonEl)
  }

  function dismissNotice (noticeEl) {
    const expDate = new Date()
    expDate.setTime(expDate.getTime() + (12 * 60 * 60 * 1000))
    const expDateString = expDate.toUTCString()
    document.cookie = `scms_notice=true;expires=${expDateString};path=/`

    removeSelf(noticeEl)
  }
}
